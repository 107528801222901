<template>
    <SwPanel
        :class="{
            [$style['block']]: true,
            [$style['block_align_center']]: position === 'center',
            [$style['block_align_left']]: position === 'left',
            [$style['block_align_right']]: position === 'right',
            [$style['block_inverted']]: inverted,
        }"
    >
        <h1
            :class="[$style['block__title'], 'sw-title']"
            v-html="title"
        />

        <div
            :class="[$style['block__text'], 'sw-text']"
            v-html="text"
        />
    </SwPanel>
</template>

<script>
export default {
  name: 'TextOnImage',

  props: {
    title: {
      type: String,
      default: 'Заголовок текстового блока'
    },

    text: {
      type: String,
      default: 'Небольшое описание вашего товара. Используйте этот текст, чтобы дать вашим клиентам представление о вашем товаре'
    },

    position: {
      type: String,
      default: 'left'
    },

    inverted: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.block {
  display: flex;
  flex-wrap: wrap;
  max-width: 516px;
  margin: auto 0 !important;

  @media screen and (max-width: @bp-mobile-small) {
    &:global(.sw-panel) {
      padding: 32px 24px;
    }
  }

  &_align_center {
    align-self: center;
    text-align: center;
  }

  &_align_left { align-self: flex-start; }
  &_align_right { align-self: flex-end; }

  &_inverted { color: #FFFFFF; }

  &__title {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    margin: 0 0 24px;
    @media screen and (max-width: @bp-mobile-small) {
      font-size: 28px;
      line-height: 36px;
    }
  }

  &__text {
    flex-basis: 100%;
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  &_inverted &_title,
  &_inverted &_text {
    color: #FFFFFF;
  }
}
</style>
